<template>
  <div>
    <div class="mb5 ar-card pa4" style="">
      <ListensChart v-if="analytic" :chart-data="analytic.listeningChart" />
    </div>
    <!-- <el-tabs v-model="activeTabName">
      <el-tab-pane label="Топ-треки" name="tracks">
        <top-tracks-table />
      </el-tab-pane>
      <el-tab-pane label="Топ релизы" name="releases">
        <top-releases-table />
      </el-tab-pane>
      <el-tab-pane label="Топ платформы" name="platforms">
        <top-platforms-table />
      </el-tab-pane>
      <el-tab-pane label="Топ страны" name="countries">
        <top-countries-table />
      </el-tab-pane>
    </el-tabs> -->
    <div class="mb5 ar-card pa4">
      <PlatformsListensChart v-if="analytic" :chart-data="analytic.listeningByPlatformChart" />
    </div>
    <div class="mb5" style="display:flex; flex-direction: row; margin: 0 -1.4em;">
      <div style="padding: 0 1.5em 0 ; min-width:50% ">
        <div class="ar-card pa4">
          <GeographyChart v-if="analytic" :chart-data="analytic.listeningGeoChart" />
        </div>
      </div>
      <div style="padding: 0 1.5em 0 ; min-width:50% ;">
        <div class="ar-card pa4">
          <PlatformsChart v-if="analytic" :chart-data="analytic.listeningGeoChart" />
        </div>
      </div>
    </div>
    <div>
      <top-tracks-table />
    </div>
  </div>
</template>

<script>
import TopTracksTable from '@/components/tables/TopTracksTable.vue';
// import TopReleasesTable from '@/components/tables/TopReleasesTable.vue';
// import TopPlatformsTable from '@/components/tables/TopPlatformsTable.vue';
// import TopCountriesTable from '@/components/tables/TopCountriesTable.vue';
import ListensChart from '@/components/analitycs/ListensChart.vue';
import GeographyChart from '@/components/analitycs/GeographyChart.vue';
import PlatformsListensChart from '@/components/analitycs/PlatformsListensChart.vue';
import PlatformsChart from '@/components/analitycs/PlatformsChart.vue';

export default {
  name: 'Analytics',
  components: {
    ListensChart,
    PlatformsListensChart,
    GeographyChart,
    PlatformsChart,
    // TopCountriesTable,
    TopTracksTable,
    // TopReleasesTable,
    // TopPlatformsTable,
  },
  data: () => ({
    graph: null,
    chartData: null,
    analytic: null,
    activeTabName: 'tracks',
  }),

  methods: {
    async getAnalytics() {
      const { data } = await this.$axios.get('/analytics/get-imixes-analytics-by-user');
      this.analytic = data;
    },
  },
  async mounted() {
    await this.getAnalytics();
  },
};
</script>

<style  lang="scss">
  .down{
    color: red;
  }

  .up{
    color: $primaryBlue;
  }
.analytic{

  svg{
    height: 100%;
    width: 100%;
  }
}

</style>

<template>
  <div style="height: auto;" class="d-flex flex-column ">
    <div class="ar-h1 ar-font-medium mb4">
      География слушателя
    </div>
    <div class="d-flex flex-row align-center" style="min-height:20em">
      <div style="min-width:50%; position: relative; min-height: 226px;" class="d-flex flex-row align-center">
        <canvas
          id="geoChart"
        />
      </div>
      <div style="min-width:50%">
        <div v-for="el in legend" :key="el.country" class="d-flex flex-row align-center justify-space-between mb2">
          <div class="d-flex flex-row">
            <div
              class="mr2"
              :style="`background-color:${el.color}; width:1em; height:1em ;border-radius:0.3em; overflow:hidden`"
            />
            <div>
              {{ el.country }}
            </div>
          </div>

          <!-- {{ el }} -->

          <div class="ar-h2 ar-font-light">
            {{ el.values }} %
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js';

export default {
  props: {
    chartData: Object,
  },
  data: () => ({
    chart: null,
    legend: [],
  }),
  mounted() {
    this.$nextTick(() => {
      const listens = this.chartData.items.slice(0, 4).reduce((acc, it) => { acc.country.push(it.country); acc.values.push(it.share.toFixed(2)); return acc; }, { country: [], values: [] });
      const borderColor = ['#1861EE', '#4B82ED', '#80A6F0', '#BACDF2', '#dfe4ef'];
      this.legend = this.chartData.items.slice(0, 4).reduce((acc, it, i) => {
        acc.push({
          country: it.country,
          values: it.share.toFixed(2),
          color: borderColor[i],
        });
        return acc;
      }, []);
      const ctx = document.getElementById('geoChart');
        this.chart = new Chart(ctx, { //eslint-disable-line

        type: 'pie',
        options: {
          aspectRatio: 1.2,
          layout: {
            padding: {
              left: -8,
              right: -8,
            },
          },
          title: {
            fontColor: 'red',
          },
          legend: {
            display: false,
          },
        },
        data: {
          labels: listens.country,
          datasets: [{
            tension: 0.4,
            fill: false,
            data: listens.values,
            borderColor,
            backgroundColor: borderColor,
            hoverOffset: 4,
          }],

        },
      });
    });
  },
};
</script>

<template>
  <div>
    <div style="overflow: auto;" class="ar-card mt5 mb3">
      <el-table :data="items" :default-sort="{ prop: 'position', order: 'descending' }">
        <!-- <template #default="scope">
          {{ scope.row }}
        </template>
      </el-table-column> -->
        <el-table-column prop="title" label="Название" align="center" width="300">
          <template #default="scope">
            <div style="display:flex; flex-direction: row">
              <div style="width:3.5em; height:3.5em;">
                <el-image :src="scope.row.imageThumb" style="width:3.5em; height:3.5em; margin-right:0.1em; border-radius: 0.5em;" fit="fill" />
              </div>
              <div class="d-flex align-center">
                <div style="margin-left:1em; text-align:start !important;  font-size: 1em; width:200px; overflow:hidden; text-overflow: ellipsis; white-space: nowrap; word-break: break-all;  font-weight: 400;">
                  {{ scope.row.title }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="artist" label="Артист" align="start" width="200px" />
        <el-table-column prop="releaseTitle" label="Альбом" align="center" />
        <el-table-column prop="country" label="Страна" align="center" />
        <el-table-column prop="paidListens" label="Платные прослушивания" align="right" width="200px" />
        <el-table-column prop="allListens" label="Все прослушивания" align="right" width="250px" />
      <!-- <el-table-column prop="audioStreams" label="Аудио стримы" width="98px" align="center" />
      <el-table-column prop="percentageStreams" label="Процент стримов" align="center" />
      <el-table-column prop="uniqueListeners" label="Уникальные слушатели" align="center" />
      <el-table-column prop="streamsPerUser" label="Стримов на пользователя" align="center" />
      <el-table-column prop="track" label="Трек" />
      <el-table-column prop="trackShazam" label="Shazam" /> -->
      </el-table>
    </div>
    <div class="d-flex flex-row justify-center">
      <el-pagination
        background
        :page-size="pageSize"
        :page-count="totalPages"
        layout="prev, pager, next"
        :total="totalCount"
        @current-change="pageChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopTracksTab',
  data: () => ({
    items: [],
    pageSize: 12,
    totalPages: 0,
    pageNumber: 1,
    totalCount: 0,
  }),
  async mounted() {
    this.getItems();
  },
  methods: {
    pageChange(page) {
      this.pageNumber = page;
      this.getItems();
    },
    async getItems() {
      const params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
      const { data } = await this.$axios.post('/analytics/get-imixes-analytics-tracks-by-user', params);
      this.totalPages = data.totalPages;
      this.totalCount = data.totalCount;
      this.items = data.items;
    },
  },
};
</script>

<template>
  <div class="d-flex flex-column">
    <div class="ar-h1 ar-font-medium mb4">
      Площадки
    </div>
    <div style="min-height:20em; position: relative;" class="d-flex flex-column">
      <canvas
        id="platformsChart"
      />
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js';

export default {
  props: {
    chartData: Object,
  },
  data: () => ({
    chart: null,
  }),

  mounted() {
    this.$nextTick(() => {
      const listens = this.chartData.items.slice(0, 5).reduce((acc, it) => { acc.country.push(it.country); acc.values.push(it.share.toFixed(2)); return acc; }, { country: [], values: [] });
      const ctx = document.getElementById('platformsChart');
        this.chart = new Chart(ctx, { //eslint-disable-line
        type: 'horizontalBar',
        options: {
          title: {
            fontColor: 'red',
          },
          legend: {
            display: false,
            position: 'right',
          },
        },
        data: {
          labels: listens.country,
          datasets: [{
            barThickness: 'flex',
            tension: 0.4,
            fill: false,
            data: listens.values,
            borderColor: ['#1861EE', '#4B82ED', '#80A6F0', '#BACDF2', '#dfe4ef'],
            backgroundColor: ['#1861EE', '#4B82ED', '#80A6F0', '#BACDF2', '#dfe4ef'],
            hoverOffset: 4,
          }],

        },
      });
    });
  },
};
</script>

<template>
  <div>
    <div class="ar-h1 mb3 ar-font-medium">
      Прослушивания
    </div>
    <div class="ar-h3">
      Все прослушивания
    </div>
    <div class="ar-h2 mb4 ar-font-light">
      {{ chartData.count }}
    </div>
    <canvas
      id="ordersChart"
    />
  </div>
</template>

<script>
import Chart from 'chart.js';

export default {
  props: {
    chartData: Object,
  },
  data: () => ({
    chart: null,
  }),
  mounted() {
    this.$nextTick(() => {
      const listens = this.chartData.items.reduce((acc, it) => { acc.dates.push(this.$moment(it.date).format('DD.MM.YYYY')); acc.values.push(it.count); return acc; }, { dates: [], values: [] });
      console.log(listens);
      const ctx = document.getElementById('ordersChart');
      // const self = this;

        this.chart = new Chart(ctx, { //eslint-disable-line
        type: 'line',
        options: {
          title: {
            fontColor: 'red',
          },
          legend: {
            display: false,
          },
        },
        data: {
          labels: listens.dates,

          datasets: [{
            tension: 0.4,
            fill: false,
            label: 'Все прослушивания',
            data: listens.values,
            borderColor: ['#18EE47'],
            hoverOffset: 4,
          }],
        },
      });
    });
  },
};
</script>

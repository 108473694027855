<template>
  <div>
    <div class="ar-h1 mb4 ar-font-medium">
      Прослушивания по площадкам
    </div>
    <div>
      {{ chartData.count }}
    </div>
    <canvas
      id="platformsListensChart"
    />
  </div>
</template>

<script>
import Chart from 'chart.js';

export default {
  props: {
    chartData: Object,
  },
  data: () => ({
    chart: null,
  }),
  methods: {
    getLineColor(platform) {
      switch (platform) {
        case 'Apple Music': {
          return '#1861EE';
        }
        case 'Spotify': {
          return '#18EE47';
        }
        case 'Deezer': {
          return '#EAEE18';
        }
        case 'Soundcloud Go': {
          return 'EE7F18';
        }
        default: {
          return 'red';
        }
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      // const listens = this.chartData.items.reduce((acc, it) => { acc.dates.push(it.date); acc.values.push(it.count); return acc; }, { dates: [], values: [] });
      const datasets = this.chartData.platforms.map((it) => ({
        tension: 0.4,
        fill: false,
        label: it.platform,
        data: it.items.reduce((acc, it) => { acc.push(it.count); return acc; }, []),
        borderColor: [this.getLineColor(it.platform)],
        hoverOffset: 4,
      }));
      const dates = this.chartData.platforms[1].items.reduce((acc, it) => { acc.push(this.$moment(it.date).format('DD.MM.YYYY')); return acc; }, []);
      const ctx = document.getElementById('platformsListensChart');
      // const self = this;

        this.chart = new Chart(ctx, { //eslint-disable-line
        type: 'line',
        options: {
          title: {
            fontColor: 'red',
          },
          legend: {
            display: true,
            position: 'bottom',
          },
        },
        data: {
          labels: dates,
          datasets,

        },
      });
    });
  },
};
</script>
